export function TeamIcon(props: React.HTMLAttributes<HTMLOrSVGElement>) {
  return (
    <svg
      className={props.className}
      xmlns='http://www.w3.org/2000/svg'
      width='30'
      height='30'
      viewBox='0 0 30 30'
      fill='none'
      {...props}
    >
      <circle cx='15' cy='15' r='15' fill='#FFE924' />
      <path d='M9.64844 22.9921L17.1093 7.00732H20.3494L12.8886 22.9921H9.64844Z' fill='#333333' />
    </svg>
  )
}
