'use client'

import { ReactNode } from 'react'
import { WithLoggedIn } from './_components/withLoggedin'

function AuthLayout({ children }: { children: ReactNode }) {
  return <div className='min-h-screen w-full bg-shell'>{children}</div>
}

export default WithLoggedIn(AuthLayout)
