/* eslint-disable @typescript-eslint/no-explicit-any */
'use client'

import { TeamIcon } from '@/components/ui/teamIcon'
import { siteRoutes } from '@/config/site'
import { useGetUserQuery } from '@/lib/redux'
import { redirect } from 'next/navigation'
import { useEffect } from 'react'

export function WithLoggedIn(Component: any) {
  return function ProtectedRoute(props: any) {
    const { data, isLoading } = useGetUserQuery()

    useEffect(() => {
      const user = data?.results[0]

      if (user && user.verified) {
        redirect(siteRoutes.yourHub)
      }
    }, [data])

    if (isLoading) {
      return (
        <div className='flex h-screen w-full items-center justify-center'>
          <TeamIcon className='h-10 w-10 animate-pulse opacity-30' />
        </div>
      )
    }

    return <Component {...props} />
  }
}
